.container_2,.container_3,.container_4,.container_5,.container_6{
    padding: 2em;
}

.container_1{
    display: flex;
    height: 80vh;
    padding: 0px 0px 0px 250px;
    background-color: var(--bgc-1);
}

.container_2{
    width: 10%;
    background-color: grey;
}

.container_2 li{
    list-style: none;
    margin: 30px 0 0 0;
}

.container_3{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: var(--bgc-2);
}

.container_4{
    background-color: var(--bgc-1);
    display: flex;
    justify-content: space-between;
}

.container_5{
    background-color: var(--bgc-1);
    display: flex;
    justify-content: space-evenly;
}

.container_5>div{
    padding: 2em;
    background-color: grey;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.container_6{
    background-color: var(--bgc-1);
    display: flex;
    justify-content: space-evenly;
}