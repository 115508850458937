.container_1{
    /* outline: red solid; */
    width: 20%;
    position: fixed;
    max-width: 150px;
    height: 100vh;
    padding: 2em;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f9f9f9;
    box-shadow: var(--box-shadow-1)
}

.container_1 a{
    text-decoration: none;
}

.logo_container{
    width: 250px;
    height: 65px;
    background-image: url('../assets/Cook-And-Chef-Final-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0px 0px 30px 0px;
}

.nav_link{
    padding: 10px;
    cursor: pointer;
    font-size: 1rem;
    text-decoration: none;
    font-weight: 400;
    color: black;
}

.nav_link.active{
    color: orangered;
}



@media (hover:hover) {
    .nav_link:hover{
        background-color: #e8e8e887;
    }
}