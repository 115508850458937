.chef-form {
    background-color: #f9f9f9;
    margin: auto;
    padding: 0px 0px 0px 250px;
}

.chef-form>div{
    margin: 0px 40px 0px 0px;
}

.title {
    text-align: center;
    font-size: 2rem;
    color: #4CAF50;
    margin-bottom: 20px;
}

.filters{
    align-items: start;
}

.filters>div:nth-child(5),
.lead-form:nth-last-child(1)>div:nth-child(7){
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.filters-container{
    background: #FFFFFF;
    padding: 2em;
    border-radius: 8px;
    margin: 0px auto 30px;
}

.filter-data-container{
    background-color: rgb(228 228 228 / 21%);
}

.filters{
    display: grid;
    gap: 10px;
    justify-content: center;
}

.input-group{
    height: 100px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
}


/* .chef-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin: 5px;
    font-weight: 700;
    transition: box-shadow 0.3s ease;
} */

.smallBtns {
    padding: 5px 10px;
    margin: 5px;
    font-weight: 500;
}

.search-btn{
    background-color: #4CAF50;
    color: #fff;
}

.reset-btn{
    outline: 1px #4CAF50 solid;
    color: #4CAF50;
    background-color: rgba(255, 255, 255, 0);
}

.button-group {
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: 30px auto;
}

.user-icon-container {
    height: 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.user-icon {
    width: 56px;
    height: 56px;
}

.error-container {
    color: red;
}

@media (hover:hover) {

    .reset-btn:hover,
    .search-btn:hover{
        box-shadow: var(--box-shadow-2);
    }
}

/* Shared styles for tablets (portrait and landscape) */
@media screen and (min-width: 768px) and (max-width: 1024px),
screen and (min-height: 768px) and (max-height: 1024px) and (orientation: landscape) {

    .filters,
    .lead-form {
        grid-template-columns: repeat(auto-fit, 250px);
    }
}

/* Styles for phones in portrait mode */
@media screen and (max-width: 767px) {

    .filters,
    .lead-form {
        grid-template-columns: repeat(auto-fit, 100%);
    }
}

/* Styles for phones in landscape mode */
@media screen and (max-height: 767px) and (orientation: landscape) {

    .filters,
    .lead-form {
        grid-template-columns: repeat(auto-fit, 30%);
        justify-content: left;
    }
}