.container_1 {
    position: fixed;
    top: 10vh;
    left: 20%;
    height: 70vh;
    z-index: 4;
    background-color: rgb(251 251 251);
    min-width: 315px;
    width: 60%;
    padding: 1em;
    border-radius: 10px;
    box-shadow: var(--box-shadow-1);
}

.container_2 {
    margin: 15px 0 0 0;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow-1);
}

.container_3 {
    display: flex;
    align-items: center;
}

.container_3_1 {
    display: flex;
    flex-direction: column
}

.container_4 {
    overflow-y: auto;
    height: 75%;
}

.container_5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container_5 >div:nth-child(1){
    display: flex;
    align-items: center;
}

.title {
    font-size: 28px;
    color: #226DFF;
    width: fit-content;
}

.container_2 textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    outline: none;
    resize: vertical;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.container_2 textarea:focus {
    border-color: #0172db;
    box-shadow: 0 0 5px rgba(1, 114, 219, 0.5);
    background-color: #fff;
}

.container_2 textarea::placeholder {
    color: #aaa;
    font-style: italic;
}

.container_2 textarea:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
    border-color: #ddd;
    color: #6c757d;
}


@media (hover:hover) {
    .btn:hover {
        box-shadow: var(--box-shadow-2);
    }
}

/* Styles for phones in portrait mode */
@media screen and (max-width: 767px) and (orientation: portrait) {
    .container_1 {
        left: 4%;
    }
}

/* Styles for phones in landscape mode */
@media screen and (max-height: 767px) and (max-width:1024px) and (orientation: landscape) {
    .container_1 {
        width: 80%;
        height: 75vh;
        left: 4%;
    }

    .container_4 {
        height: 70%;
    }
}

/* Shared styles for tablets (portrait and landscape) */
@media screen and (min-width: 768px) and (max-width: 1024px),
screen and (min-height: 768px) and (max-height: 1024px) and (orientation: landscape) {
    .container_1 {
        /* left: 20%; */
    }
}