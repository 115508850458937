.container_1{
    display: flex;
    justify-content: flex-end;
    padding: 2em;
    background-color: var(--bgc-1);
}

.userModal{
    width: 100px;
    padding: 1em;
    position: absolute;
    top: 100px;
    background-color: var(--bgc-1);
    box-shadow: var(--box-shadow-1);
}   

.userModal div{
    cursor: pointer;
    padding: 10px;
}

@media (hover:hover) {
    .userModal div:hover{
        background-color: var(--hover-color-1);
    }
}