.container_1 {
    padding: 2em;
    height: 89vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f0f0f0;
}

.container_2 {
    width: 130px;
}

.container_2_1::before {
    content: '';
    display: block;
    background-image: url('../assets/Cook-And-Chef-Final-logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: inherit;
    height: 60px;
}

.container_3 {
    display: flex;
    justify-content: center;
    padding: 2em;
    height: 300px;
}

.container_3 > div {
    /* outline: greenyellow solid; */
    width: 40%;
    margin: 0 1em;
}

.container_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
    color: white;
}

.container_4_1::before {
    content: '';
    display: block;
    background-image: url('../assets/login-img.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 500px;
    height: 500px;
}

.container_5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.container_5 select {
    background-color: #ffffff;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.container_5 select:focus {
    border-color: #888;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.container_5 select:hover {
    border-color: #bbb;
}

.container_5 form {
    padding: 0;
    width: 300px;
}

.container_5 h1 {
    text-align: center;
    margin-bottom: 1em;
    color: #333;
}

.container_5_2{
    /* outline: red solid; */
    width: 310px;
    height: 350px;
    position: absolute;
    background-color: #f0f0f0;
    padding: 1em;
}

.container_6 {
    display: flex;
    flex-direction: column;
}

.container_6 input,
.container_6_1 input,
.container_5_2>div>div>div>input{
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin: 10px 0px 10px 0;
}

.container_1 input:focus,.container_1 button:focus {
    border-color: #aaa;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.container_6>button:nth-last-child(1),
.container_5_2>div>button,
.container_5_2>div>div>div>button,
.container_5_2>div>div>button
{
    width: 100%;
    padding: 10px;
    background-color: #0173db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 0px 5px 0;
}

.container_5_2>div>div>div>button{
    width: fit-content;
}

.container_5_2>div>div>div>button:disabled{
    background-color: #a9a9a9;
}

.container_5_2>div>div>div>input{
    width: 70%;
    max-width: 400px;
    margin: 0px 20px 0 0;
}

.container_5_2 .container_6_1{
    height: 50px;
}

.container_6 >button:nth-last-child(1):hover {
    background-color: #005bb5;
}

.container_6_1>button
{
    outline: none;
    border: none;
    position: relative;
    cursor: pointer;
    background-color: #ffffff00;
    color: #0173db;
    top: -43px;
    left: 262px;
}

.container_6 button {
    text-decoration: none;
    align-self: flex-end;
    color: #0173db;
    transition: color 0.3s ease;
    margin: 0px 0px 10px 0;
    outline: none;
    border: none;
    cursor: pointer;
}

.container_7 {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.container_8{
    /* outline: red solid; */
    display: flex;
    justify-content: center;
}

/* Responsive styles */
@media (max-width: 767px) {
    .container_4 {
        display: none;
    }

    .container_3 {
        padding: 1em;
    }

    .container_3 > div {
        width: 100%;
    }
}