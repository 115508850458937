.container_1>div:nth-child(1),
.table {
    padding: .5em;
}

.container_1 {
    display: flex;
    padding: .5em;
    height: 80vh;
    width: 80%;
    margin: 0px 0px 0px 250px;
    background-color: var(--bgc-2);
    flex-direction: column;
    border-radius: 10px;
}

.container_1>div:nth-child(1) {
    display: flex;
    justify-content: space-between;
}

.container_1>div:nth-child(2){
        padding: 0 0 150px 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.table th {
    padding: 10px 0px 10px 10px;
    text-align: left;
    border-bottom: 2px solid #ddd;
    white-space: nowrap;
    text-align: center;
}

.table tr {
    border-bottom: 1px solid #dddddd;
}

.table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    padding: 20px 0px 20px 9px;
    text-align: center;
}

.table td:nth-child(4) {
    display: flex;
    justify-content: center;
    margin: auto;
}

.table td:nth-child(4) div {
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 0, 0, 0.873);
}

.table td.active div {
    background-color: #4caf4fcd;
}

.actionBtn {
    font-size: larger;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    padding: 10px;
    cursor: pointer;
}

.actionModal {
    /* outline: red solid; */
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 1em;
    background-color: var(--bgc-1);
    box-shadow: var(--box-shadow-1);
    align-items: flex-start;
}

.actionModal button {
    margin: 5px;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
}

.createModal select {
    padding: 10px;
    border-radius: 4px;
    background-color: var(--bgc-1);
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.createModal form>div:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
}

.createModal form>div:nth-child(1) div{
    width: 500px;
}

.createModal form>div:nth-child(1)>div:nth-child(2) {
    display: flex;
    align-items: center;
    padding: 0 0 30px 0;
    width: 230px;
    justify-content: space-between;
}

.createModal form>div:nth-child(3)>div,
.createModal form>div:nth-child(4)>div,
.createModal form>div:nth-child(5)>div {
    font-size: 16px;
    display: flex;
    width: 450px;
    justify-content: space-between;
    margin: 10px 0px 10px 0;
}

.createModal form>div:nth-child(3)>div>div,
.createModal form>div:nth-child(4)>div>div,
.createModal form>div:nth-child(5)>div>div {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.createModal form>div:nth-child(3)>div>div input[type='checkbox'],
.createModal form>div:nth-child(4)>div>div input[type='checkbox'],
.createModal form>div:nth-child(5)>div>div input[type='checkbox'] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    accent-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    margin: 3px 5px 0px 0px;
}

@media (hover:hover) {
    .table tr:hover {
        background-color: #c6c6c6a2;
    }

    .actionModal button:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: 1000px) {
    .table thead {
        display: none;
    }

    .table,
    tbody,
    tr {
        display: block;
        text-align: right;
    }

    .table tr {
        border-bottom: 3px solid #dddddd;
    }

    .table td {
        display: flex;
        text-align: right;
        align-items: baseline;
        justify-content: space-between;
    }

    .table td::before {
        content: attr(data-label);
        left: 15%;
    }
}