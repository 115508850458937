.container_1 {
    position: fixed;
    width: 500px;
    left: 30%;
    background-color: var(--bgc-2);
    box-shadow: var(--box-shadow-2);
    padding: 2em;
}

.container_1>div:nth-child(1) {
    display: flex;
    justify-content: space-between;
}