.inputGroup {
    display: flex;
    flex-direction: column;
    height: 80px;
}

.inputGroup label{
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.inputField {
    margin: 10px 0px;
    width: 70%;
    font-size: large;
    outline: none;
    border: none;
    border-bottom: 1px #00000052 solid;
    background-color: rgba(255 255 255 0);
}

.errorContainer {
    color: red;
}