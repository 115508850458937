* {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

:root {
    --padding-1: 1em;
    --box-shadow-1: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    --box-shadow-2: 0 4px 8px rgba(0, 0, 0, 0.6);
    --hover-color-1: #e8e8e887;
    --bgc-1: #f9f9f9;
    --bgc-2: white;
}

#root>div{
    background-color: var(--bgc-1);
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin: 5px;
    font-weight: 700;
    transition: box-shadow 0.3s ease;
    color: white;
}

.blue_btn {
    background-color: #226dff;
}

.red_btn {
    background-color: rgb(255, 0, 0);
}

.green_btn {
    background-color: #4CAF50;
}

.gray_btn{
    background-color: gray;
}

.btn:disabled {
    background-color: rgba(0, 0, 0, 0.281);
}


@media (hover:hover) {
    .btn:hover {
        box-shadow: var(--box-shadow-2);
    }
}