.cuisines-sub-container{
    max-height: 100px;
    overflow-y: auto;
    box-shadow: 3px 3px 4px 0px #00000024;
    background-color: white;
}

.cuisines-sub-container>div{
    background-color: white;
    cursor: pointer;
}

.cuisines-sub-container>div:hover{
    background-color: rgba(0, 0, 0, 0.031);
}

.rti--container{
    border: none !important;
    border-bottom: 1px #00000052 solid !important;
    border-radius: 0 !important;
    width: 70%;
    gap: 0 !important;
    padding: 0 !important;
    margin: 5px 0px !important;
    font-size: large !important;
    max-height: 200px !important;
    overflow-y: auto;
}

.rti--container button{
    font-size: 10px !important;
}

.rti--container:focus-within{
    box-shadow: none !important;
}

.rti--tag{
    padding: 0px 0px 0px 5px !important;
    margin: 0px 0px 0px 5px;
    font-weight: normal;
    font-size: small;
}

.rti--input{
    width: 250px;
}