.lead_form_container,
.filter_data_container{
    background: #FFFFFF;
    padding: 2em;
    border-radius: 8px;
    margin: 0px auto 30px;
}

.sub_title_1 {
    font-size: 28px;
    color: #226DFF;
    margin: 0 0 5px -18px;
    width: fit-content;
}

.sub_title_2 {
    width: fit-content;
    color: #FF4700;
    margin: 15px 0px 15px -18px;
    font-size: 24px;
}

.filter_data_container{
    height: 80vh;
    overflow-y: auto;
    padding: 5px;
    border-bottom: 1px black solid;
}

.lead_form{
    display: grid;
    grid-template-columns: repeat(auto-fit,300px);
    gap: 10px;
}

.lead_form>div{
    max-width: 300px;
}

.input_group{
    height: 100px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
}


.custom_checkbox_container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.custom_checkbox {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 3px solid #00b386;
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    cursor: pointer;
}

.custom_checkbox:checked::after {
    content: '✔';
    position: absolute;
    top: -3px;
    left: 2px;
    color: #00b386;
    font-size: 14px;
    font-weight: 900;
}

.error_container{
    color: red;
}

.lead_form:nth-last-child(1)>div:nth-child(7){
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.button_group{
    display: flex;
    justify-content: center;
    padding: 0 80px 0px 0px;
}

.clear_lead_btn{
    outline: 1px #4CAF50 solid;
    color: #4CAF50;
    background-color: rgba(255, 255, 255, 0);
}

.add_lead_btn{
    background-color: #4CAF50;
    color: #fff;
}

@media (hover:hover) {
    .add_lead_btn:hover,
    .clear_lead_btn:hover{
        box-shadow: var(--box-shadow-2);
    }
}