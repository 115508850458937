.container_1 {
    padding: 2em;
    background-color: white;
    position: fixed;
    color: black;
    top: 20%;
    left: 20%;
    width: 900px;
    box-shadow: var(--box-shadow-2);
    background-color: var(--bgc-1);
    display: flex;
    flex-direction: column;
}

.container_1>div:nth-child(1) {
    display: flex;
    justify-content: space-between;
}

.createModal>div {
    display: flex;
    justify-content: flex-end;
}

.createModal input[type='text'],
.createModal input[type='email'] {
    margin: 0px 230px 0px 0px;
    background-color: var(--bgc-1);
}

.createModal select {
    padding: 10px;
    border-radius: 4px;
    background-color: var(--bgc-1);
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.createModal form>div:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
}

.createModal form>div:nth-child(1)>div:nth-child(2) {
    display: flex;
    align-items: center;
    padding: 0 0 30px 0;
    width: 230px;
    justify-content: space-between;
}

.createModal form>div:nth-child(3)>div,
.createModal form>div:nth-child(4)>div {
    font-size: 16px;
    display: flex;
    width: 450px;
    justify-content: space-between;
    margin: 10px 0px 10px 0;
}

.createModal form>div:nth-child(3)>div>div,
.createModal form>div:nth-child(4)>div>div {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.createModal form>div:nth-child(3)>div>div input[type='checkbox'],
.createModal form>div:nth-child(4)>div>div input[type='checkbox'] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    accent-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    margin: 3px 5px 0px 0px;
}

.createModal form>div:nth-child(5) {
    margin: 15px 0px 0px -8px;
}