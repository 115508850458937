.collapseTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.collapseTable th {
    padding: 10px 0px 10px 10px;
    text-align: left;
    border-bottom: 2px solid #ddd;
    white-space: nowrap;
}

.collapseTable tr {
    border-bottom: 1px solid #dddddd;
}

.data_cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    padding: 20px 0px 20px 9px;
}

.details_container {
    padding: 1em;
    background-color: black;
}

.details_container>h3 {
    color: orangered;
}

.details_container>div:nth-child(5)>div:nth-child(6) {
    font-weight: 700;
}

.details_container>div:not(:first-child) {
    display: flex;
    justify-content: space-around;
    padding: 1em;
    flex-wrap: wrap;
}

.details_container>div>div {
    /* outline: red solid; */
    width: 30%;
}

.details_container>div>div input:not([name="cuisines"]):not([type="checkbox"]) {
    color: #848484;
    padding: 25px 30px 0px 0px;
    margin: 0px 0px 15px 0;
    border: none;
    border-bottom: 1px #adadad solid;
    outline: none;
    font-size: medium;
}

.role_container>div:nth-child(2) {
    display: flex;
    max-width: 150px;
    justify-content: space-between;
}

.details_container>div>div>div {
    font-weight: 700;
    color: black;
}

.no_data_container {
    padding: 3em;
    text-align: center;
    font-size: large;
    font-weight: 500;
    background-color: #f9f9f9;
}

@media (hover:hover) {
    .collapseTable tr:hover {
        background-color: #c6c6c6a2;
    }

    .details_container {
        background-color: white;
    }

    .cuisines_container>div:hover {
        background-color: rgba(0, 0, 0, 0.031);
    }
}

@media screen and (max-width: 1000px) {
    .collapseTable thead {
        display: none;
    }

    .collapseTable table,
    tbody,
    tr {
        display: block;
        text-align: right;
    }

    .collapseTable tr {
        border-bottom: 3px solid #dddddd;
    }

    .collapseTable td {
        display: flex;
        text-align: right;
        align-items: baseline;
        justify-content: space-between;
    }

    .collapseTable td::before {
        content: attr(data-label);
        left: 15%;
    }

    .data_cell {
        width: 34%;
        padding: 5px;
    }
}